$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eceff1;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #455a64;
$gray-700: #37474f;
$gray-800: #263238;
$gray-900: #212529;
$black: #000;

$blue: #1976d2;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #d32f2f;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #388e3c;
$teal: #20c997;
$cyan: #0dcaf0;

$primary: $blue;
$secondary: $red;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$font-family-sans-serif: "Open Sans", sans-serif;
