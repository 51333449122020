// Custom vars
@import "variables";

// Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Google Merriweather
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

// Google Open Sans
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
}
.top-bar {
  .contact {
    a {
      color: $white;
      text-decoration: none;
      border-bottom: 2px solid $white;
    }
  }

  .social {
    display: inline-block;

    a {
      color: $white;
      text-decoration: none;

      svg {
        color: $white;
        border: 1px solid $white;
        border-radius: 3px;
        display: inline-block;
        margin-right: 6px;
        padding: 3px;
        width: 32px;
        height: 32px;
        fill: currentColor;
      }
    }

    svg:last-child {
      margin-right: 0;
    }
  }
}

.main-nav {
  ul {
    font-family: "Merriweather", serif;
    font-size: 1.25rem;

    .nav-link {
      color: $light;
      font-weight: bold;
      margin: 0 10px;
      padding: 0;

      border-bottom: 2px solid $primary;

      &:hover {
        border-bottom: 2px solid $light;
      }
    }
  }
}

.lower-nav {
  a {
    color: $dark;
  }

  .social {
    a {
      color: $dark;
      text-decoration: none;

      svg {
        color: $dark;
        border: 1px solid $dark;
        border-radius: 3px;
        display: inline-block;
        margin-right: 6px;
        padding: 3px;
        width: 32px;
        height: 32px;
        fill: currentColor;
      }
    }

    svg:last-child {
      margin-right: 0;
    }
  }
}

form.contact {
  label {
    font-weight: bold;
  }
  input,
  textarea {
    &::placeholder {
      color: $gray-500;
    }
  }
}

@media screen and (max-width: 576px) {
  .top-bar {
    .contact {
      margin-bottom: 10px;
    }
  }
  .main-nav {
    ul {
      font-size: 1.1rem;
      padding-top: 20px;

      .nav-link {
        margin: 0 5px;
      }
    }
  }
}
